import {RichText as GVRichText} from '@gvdasa/components';
import {useMacros} from 'shared/hooks';

interface IProps {
  disabled?: boolean;
  disableMacros?: boolean;
  disableFilePicker?: boolean;
  value: string;
  onChange: (value: string) => void;
}

export const RichText: React.FC<IProps> = ({
  disabled,
  disableFilePicker,
  value,
  onChange,
}) => {
  const {macros} = useMacros();

  return (
    <GVRichText
      disableFilePicker={disableFilePicker}
      disabled={disabled}
      macros={macros}
      value={value}
      onChange={onChange}
    />
  );
};
